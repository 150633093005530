export const data = [
  { "name": "Ahmedzai" },
  { "name": "Almani" },
  { "name": "Ameeri" },
  { "name": "Amirari" },
  { "name": "Askani" },
  { "name": "Badin Zai" },
  { "name": "Badini" },
  { "name": "Bagayzani" },
  { "name": "Bahadurzai" },
  { "name": "Bahalkani" },
  { "name": "Bahawalanzai" },
  { "name": "Bajoi" },
  { "name": "Bakhrani" },
  { "name": "Bangulzai" },
  { "name": "Barmani" },
  { "name": "Barr" },
  { "name": "Bhurgri" },
  { "name": "Bhutani" },
  { "name": "Bijarani" },
  { "name": "Bijjarzahi" },
  { "name": "Bilwani" },
  { "name": "Bizenjo" },
  { "name": "Bughlani" },
  { "name": "Bugti" },
  { "name": "Buledi" },
  { "name": "Bulfati" },
  { "name": "Buzdar" },
  { "name": "Chakerzehi" },
  { "name": "Chakrari" },
  { "name": "Chandio" },
  { "name": "Chang" },
  { "name": "Changwani" },
  { "name": "Chanorwani" },
  { "name": "Chelmarzai" },
  { "name": "Chutani" },
  { "name": "Dagar zai" },
  { "name": "Dagarzai" },
  { "name": "Damanis" },
  { "name": "Dannarzai" },
  { "name": "Dareshak" },
  { "name": "Darzada" },
  { "name": "Dashti" },
  { "name": "Dehani" },
  { "name": "Dehwar" },
  { "name": "Dodai tribe" },
  { "name": "Domki" },
  { "name": "Drakhshan" },
  { "name": "Durrazai" },
  { "name": "Esani" },
  { "name": "Essazai" },
  { "name": "Fatahni" },
  { "name": "Gabol" },
  { "name": "Gadani" },
  { "name": "Gadhi" },
  { "name": "Gadi" },
  { "name": "Gajani" },
  { "name": "Gamshadzehi" },
  { "name": "Gashkori" },
  { "name": "Ghanbarzehi" },
  { "name": "Ghazakandi" },
  { "name": "Ghazini" },
  { "name": "Ghoramzai" },
  { "name": "Gichki" },
  { "name": "Goarazai" },
  { "name": "Gola" },
  { "name": "Gopang" },
  { "name": "Gorgage" },
  { "name": "Gorgaij" },
  { "name": "Gorhani" },
  { "name": "Gorshani" },
  { "name": "Gudaro" },
  { "name": "Guldani" },
  { "name": "Gulfad" },
  { "name": "Gurgunari" },
  { "name": "Gurmani" },
  { "name": "Gyandar" },
  { "name": "Halazai" },
  { "name": "Harooni" },
  { "name": "Hasanzehi" },
  { "name": "Hasni" },
  { "name": "Heesbani" },
  { "name": "Hooth" },
  { "name": "Ismailzahi" },
  { "name": "Jagirani" },
  { "name": "Jakhrani" },
  { "name": "Jalalzai" },
  { "name": "Jalbani" },
  { "name": "Jamaldini" },
  { "name": "Jamali" },
  { "name": "Jarwar" },
  { "name": "Jatoi" },
  { "name": "Jiskani" },
  { "name": "Joonglani" },
  { "name": "Kadahe" },
  { "name": "Kalmati" },
  { "name": "Kalpar" },
  { "name": "Kambarzahi" },
  { "name": "Kando Khel" },
  { "name": "Kanera Khel" },
  { "name": "Karlu" },
  { "name": "Kashani" },
  { "name": "Katbal" },
  { "name": "Kenagzai" },
  { "name": "khaira, khera or khero" },
  { "name": "Khalol" },
  { "name": "Kharani" },
  { "name": "Khetran" },
  { "name": "Khosa" },
  { "name": "Khuloi" },
  { "name": "Khurasani" },
  { "name": "Khushk" },
  { "name": "Kiazai" },
  { "name": "Kizilbash" },
  { "name": "Korai" },
  { "name": "Kubda" },
  { "name": "Kubdani" },
  { "name": "Kulachi" },
  { "name": "Kupchanis" },
  { "name": "Kurd Baloch Tribe" },
  { "name": "Lalwani" },
  { "name": "Lamba" },
  { "name": "Langove" },
  { "name": "Lanjwani" },
  { "name": "Lashari" },
  { "name": "Laskani" },
  { "name": "Leghari" },
  { "name": "Lehri" },
  { "name": "Lewari" },
  { "name": "Loharani" },
  { "name": "Lund" },
  { "name": "Madranshahi" },
  { "name": "Magsi" },
  { "name": "Makrani" },
  { "name": "Malghani" },
  { "name": "Mamhdani" },
  { "name": "Mandozai" },
  { "name": "Mandwani" },
  { "name": "Marri" },
  { "name": "Mashori" },
  { "name": "Mastoi" },
  { "name": "Masuri" },
  { "name": "Mazari" },
  { "name": "Mazarzai" },
  { "name": "Meerwani" },
  { "name": "Mehrabkhanzai" },
  { "name": "Mengal" },
  { "name": "Mewtani" },
  { "name": "Mirani" },
  { "name": "Mirjat" },
  { "name": "Mobaraki" },
  { "name": "Mohammad Hassani" },
  { "name": "Mohammadzahi" },
  { "name": "Mollazehi" },
  { "name": "Mondrani" },
  { "name": "Moosiani" },
  { "name": "Moosiani Kubdani" },
  { "name": "Mrizai" },
  { "name": "Mugheri" },
  { "name": "Mullazai" },
  { "name": "Nandwani" },
  { "name": "Narouie" },
  { "name": "Naukri" },
  { "name": "Nauri" },
  { "name": "Nausherwani" },
  { "name": "Nindwani" },
  { "name": "Nizamani" },
  { "name": "Noh Zai" },
  { "name": "Nohani" },
  { "name": "Notekzai" },
  { "name": "Nothazai" },
  { "name": "Nutkani" },
  { "name": "Pandrani" },
  { "name": "Pasoi" },
  { "name": "Perozai" },
  { "name": "Perozani" },
  { "name": "Pirakzai" },
  { "name": "Pirkani" },
  { "name": "Pitafi" },
  { "name": "Qahtani" },
  { "name": "Qaisrani" },
  { "name": "Qalandrani" },
  { "name": "Qambrani" },
  { "name": "Qandrani" },
  { "name": "Qusmani" },
  { "name": "Rahija" },
  { "name": "Rahimi" },
  { "name": "Rahmanzai-Umerani" },
  { "name": "Raisani" },
  { "name": "Rakshani" },
  { "name": "Ranjhani" },
  { "name": "Rigi" },
  { "name": "Rind" },
  { "name": "Rodeni" },
  { "name": "Rodnani" },
  { "name": "Sadozai" },
  { "name": "Saeedani" },
  { "name": "Sahlani" },
  { "name": "Sajidi" },
  { "name": "Sakhani" },
  { "name": "Sanjrani" },
  { "name": "Saravani" },
  { "name": "Sardaizai" },
  { "name": "Sargani" },
  { "name": "Sarmastani" },
  { "name": "Sarparah" },
  { "name": "Sasoli" },
  { "name": "Sethwi" },
  { "name": "Seyahsawar" },
  { "name": "Shadadzai" },
  { "name": "Shadani" },
  { "name": "Shahalzai" },
  { "name": "Shahani" },
  { "name": "Shahdadzai" },
  { "name": "Shahizai" },
  { "name": "Shahozai" },
  { "name": "Shahwani" },
  { "name": "Shaliani" },
  { "name": "Shambhani" },
  { "name": "Shar" },
  { "name": "Sherkhlani" },
  { "name": "Sirgulzai" },
  { "name": "Sobdarani" },
  { "name": "Sohrani" },
  { "name": "Soomrani" },
  { "name": "Suhrabzai" },
  { "name": "Sumalani" },
  { "name": "Sumrah" },
  { "name": "Sundrani" },
  { "name": "Taherzai" },
  { "name": "Talani" },
  { "name": "Talpur" },
  { "name": "Tangwani" },
  { "name": "Tanwri" },
  { "name": "Tauqi" },
  { "name": "Umrani" },
  { "name": "Waddani" },
  { "name": "Wadela" },
  { "name": "Yallanzai" },
  { "name": "Yarahmadzahi" },
  { "name": "Zagar Mengal" },
  { "name": "Zahnoozai" },
  { "name": "Zamurani" },
  { "name": "Zangaza" },
  { "name": "Zarakzahi Zehri" },
  { "name": "Zardari" },
  { "name": "Zarkani" },
  { "name": "Zarrani" },
  { "name": "Zehri" }
]
